import {Categories} from "../lib/consts";
import React from 'react';
import {Link} from "gatsby";
/*import Search from "./search";*/
import {Helmet} from "react-helmet/es/Helmet";

const Header = (props) => {
    return (
        <div className="mt-6 text-center">
            <Helmet>
                <script async src="https://cse.google.com/cse.js?cx=db51ccc8c5e0dc0b8"></script>
            </Helmet>
            <div className={"mb-6"}>
                <Link to="/">
                    <img className={"mx-auto"} alt="Regulator Warnings Logo" src={"/images/logos/logo.png"} width={400}
                         height={126}/>
                </Link>
            </div>
            <div className={"border-b-2 border-accent-2 min-h-3.5 flex items-center justify-center"}>
                {Object.keys(Categories).map(key => {
                    const slug = Categories[key].slug;
                    return <Link to={`/${slug}`} key={key} style={{lineHeight: "3.5rem", color: "#850202"}}
                                 className={`px-4 inline-block min-h-full text-lg font-semibold ${slug === props.category ? 'border-2 border-accent-2 border-b-0 bg-accent-2' : ''} ${slug === 'foi' ? 'hidden' : ''}`}>
                        {Categories[key].title}
                    </Link>
                })}
                <div className={"hidden lg:block ml-8"}>
                    {/*<Search onlyIcon={true} width={30} height={30}/>*/}
                    <div className={"gcse-search"}></div>
                </div>
            </div>
        </div>
    )
}

export default Header;
