import Footer from '../components/footer';
import React, {useEffect} from 'react';
import {withPrefix} from 'gatsby'
import {Helmet} from "react-helmet/es/Helmet";

export default function Layout({children}) {
    useEffect(() => {
        const headElement = document.getElementsByTagName("head")[0];
        const timeout = setTimeout(() => {
            if (!document.querySelector('script[src*="gtm"]')) {
                const gtmScript = document.createElement("script");
                gtmScript.src = `${withPrefix(`gtm.js`)}`;
                gtmScript.async = true;
                headElement.appendChild(gtmScript);
            }
        }, 1200);
        return () => {
            clearTimeout(timeout);
        };
    }, []);

    return (
        <>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <noscript>
                    {`<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-THPVXJJ"
                                height="0" width="0" style="display:none;visibility:hidden"></iframe>`}
                </noscript>
            </Helmet>
            <div className="min-h-screen">
                <main>{children}</main>
            </div>
            <Footer/>
        </>
    )
}
