import Container from './container'
import React from 'react';

export default function Footer() {
  return (
    <footer className="bg-gray mt-4">
      <Container>
        <div className="py-6 flex flex-col items-center justify-center">
            <p className="text-white mb-4 text-sm">&copy; regulatorwarnings.com</p>
            <img alt="Regulator Warnings Logo" src={"/images/logos/logo-white.png"} width={250} height={78} />
        </div>
      </Container>
    </footer>
  )
}
